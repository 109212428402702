.App {
  text-align: left;
}

.App-header {
  background-color: #714bbd;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  font-weight: bold;
  font-size: 20px;
  padding: 16px;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  z-index: 2;
}

.App-content {
  display: flex;
  flex-direction: row;
  min-height: calc(100vh - 20px - 32px);
}

@media screen and (max-width: 770px) {
  .App-header {
    height: auto;
    position: static;
  }

  .App-content {
    display: block;
  }
}
